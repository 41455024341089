import React, { useEffect } from 'react'
import CampaignParticipants from '../../components/list/campaign/CampaignParticipants'
import UsersList from '../../components/list/user/UsersList'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'

function Users() {
  
  
  return (
    <div id="wrapper">
        <Header />

        <div id="content" className='container pb-0'>
            <div className="row">
                {/* <MainMenu /> */}
                <div className="col-md-12">
                <div className='box'>
                  <div className="box-header">
                   <h5>Users</h5>
                  </div>
                  <div className="box-body">
                  <UsersList />

                  </div>
              </div>
                </div>
            </div>

        </div>

        <Footer />
    </div>
  )
}

export default Users