import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { CampaignHttpService } from "../../../../services/campaign";

function CampaignForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      CampaignHttpService.loadCampaign(id).then(item => {
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setImage(item.image)

  }

  const _previewFile = (file) => {

    const objectUrl = URL.createObjectURL(file)
    setImage(objectUrl);
    setRefresh(refresh + 1)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    if (!data.name) {
      setError("Please provide a name");
      return;
    }

    var body = {
      "id": id ? id : data.id,
      "name": data.name,
      "description": data.description,
      "number_of_squares": data.number_of_squares < 70001 ? data.number_of_squares : 7000,
      "square_width": data.square_width,
      "square_margin": data.square_margin,
      "square_opacity": data.square_opacity,
      "file": file,
    }

    setDone(false)
    setLoading(true)
    CampaignHttpService.saveCampaign(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.callback();
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const _handleFileClick = (e) => {
    e.preventDefault();
    fileRef.current.click();

  }

  const _handleFileChange = (e) => {
    var file = e.target.files[0]
    if (e.target.files) {
      setFile(file)
      _previewFile(file)
    }

  }
  return (
    <div>
      <form className="mb-5" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="Name"
                    value={watch('name')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("name", { required: true })}
                  />
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextareaAutosize
                    className="form-control"
                    label="Description"
                    placeholder="description"
                    value={watch('description')}
                    variant="outlined"
                    {...register("description", { required: false })}
                  />
                </div>
              </div>


            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <img src={image ? image : `/assets/images/default.png`} alt="Upload" onClick={_handleFileClick} className="img-fluid" />
                  <input type="file" ref={fileRef} className="hide" onChange={_handleFileChange} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Number of Squares"
                      placeholder="2500 recommended, 7000 most"
                      value={watch('number_of_squares')}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      {...register("number_of_squares", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Square width"
                      placeholder="e.g. 2px or 1%"
                      value={watch('square_width')}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      {...register("square_width", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Margin of Squares"
                      placeholder="e.g. 2px or 1%"
                      value={watch('square_margin')}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      {...register("square_margin", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="form-group">
                    <TextField
                      fullWidth
                      label="Squares Opacity"
                      placeholder="number between 0 and 1,0.15 default "
                      value={watch('square_opacity')}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      {...register("square_opacity", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="form-group mt-5 mb-5">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <EditIcon /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Campaign"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default CampaignForm;
