import * as React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { CampaignHttpService } from '../../../../services/campaign';
import DeleteDialog from '../../shared/DeleteDialog';
import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translate } from '../../../../utils';

const table = "campaign"

export default function CampaignListSquare({...props}) {
  const [deleteDialogIsOpen,setDeleteDialogIsOpen] = React.useState(false);
  const [selectedId,setSelectedId] = React.useState(0);
  const [data,setData] = React.useState([]);
  const [count,setCount] = React.useState(0);
  const [loading,setLoading] = React.useState(false);
  const [refresh,setRefresh] = React.useState(0);

  const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}
  
  useEffect(() => {

    _getData()

  }, [])

  const _getData = (page=1)=>{

    CampaignHttpService.loadCampaigns().then(data=>{
      setData(data)
      setCount(data.length)
    })

  }


  const _handleDelete = (e,id) => {
    console.log()
    setSelectedId(id);
    setDeleteDialogIsOpen(true)
    setRefresh(refresh+1)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(campaign=>campaign.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh+1)
  }



  return (
    <>
    <div className='box'>
        <div className="box-header">
        <h5 >{_t("Campaigns")}</h5>
          <div className="btns">
            <Link to="/cms/campaign" className="btn"><i className="fa fa-plus"></i></Link>
          </div>
        </div>
        <div className="box-body d-flex">
          {data.map(item=>(
            <div key={item.id+"cmp"} className='campaign-item'>
              <div className="image">
                <Link to={`/cms/campaign/${item.id}`}>
                  <img src={item.avatar} alt={item.name} />
                </Link>
              </div>
              <div className="caption">
                <a href={`/cms/campaign/${item.id}`}>{item.id} {item.name}</a>
                <div className="btns">
                <button className="btn">
                        <Link to={`/cms/campaign/${item.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                    </button>
                    <button className="btn">
                        <a href="#Delete" onClick={(e)=>{_handleDelete(e,item.id)}}>
                            <i className="fa fa-trash"></i>
                        </a>
                    </button>
                </div>
              </div>
            </div>
          )
          )}
          
        </div>
    </div>
    
          <DeleteDialog
          url={`/campaign/${selectedId}`}
          isOpen={deleteDialogIsOpen}
          key={"mdl"+deleteDialogIsOpen+selectedId}
          setOpen={setDeleteDialogIsOpen}
          notifyDone={_onDeleteDone}
        />
    </>
  );
}