import React from 'react'

function SocialShareIcons(url,name="") {

    var text = name
    var textWithUrl = text+"\n"+url


    return (
        <div className=" social-icons-gallery w-50p color-gray2">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" title='Share on Facebook' rel="noreferrer" ><i className="fa fa-facebook fa-lg"></i></a>
            <a href={`http://twitter.com/share?text=${text}&url=${url}&hashtags=JinaAmini`} title='Share on Twitter' target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-lg"></i></a>
            <a href={`https://telegram.me/share/url?url=${url}&text=${text}`} target="_blank" title='Share on Telegram' rel="noreferrer"><i className="fa fa-telegram fa-lg"></i></a>
            <a href={`whatsapp://send?text=${textWithUrl}`} data-action="share/whatsapp/share" title='Share on Whatsapp' target="_blank" rel="noreferrer"><i className="fa fa-whatsapp fa-lg"></i></a>
        </div>
    )
}

export default SocialShareIcons