import SettingsIcon from '@mui/icons-material/Settings'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate } from '../../../utils';

function Header() {

	const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}
  return (
    <header>
            
            <div className="container  ">
                    	<div className="de-header-flex">
                        <div id="logo">
                            <a href="/cms/">
                                <img className="logo" src="/assets/images/logo.png" alt="" />
                            </a>
                        </div>

                        <span id="menu-btn"></span>

							<nav className="md-flex">
									<ul id="mainmenu">
										
										<li><Link to="/cms/dashboard">{_t("Dashboard")}</Link>
										</li>
										<li><Link to="/cms/campaigns">{_t("Campaign")}</Link>
											{/* <ul>
												<li><Link to="/cms/campaigns">view campaigns</Link></li>
												<li><Link to="/campaign">Add new</Link></li>
											</ul> */}
										</li>
										<li><Link to="/cms/users">{_t("Users")}</Link></li>
										<li><Link to="/cms/gallery">{_t("Gallery")}</Link></li>
									</ul>
								</nav>
							

                            <div className='header-btns' >
								<a title="View website" className='' target="_blank" href="/">
									<i className="fa fa-globe"></i>
								</a>
								<Link to="/cms/settings"><SettingsIcon /></Link>
                                <a title="Sign Out" className=''  href="/?signout=1">
									<i className="fa fa-sign-out"></i>
								</a>
							</div>
                </div>
            </div>
        </header>
  )
}

export default Header