import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import GalleryItems from '../../components/gallery/GalleryItems';
import Campagin from '../../components/shared/Campagin'
import Footer from '../../components/shared/Footer';
import Header from '../../components/shared/Header'
import { BaseHttpService } from '../../services/base';
import parse from 'html-react-parser'
import { translate } from '../../utils';
import { LanguageContext } from '../../contexts/LanguageContext';

function Home() {
    const [setting, setSetting] = useState()

    const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}

    useEffect(() => {
        BaseHttpService.loadSettings().then(settings => {
            if (settings.length > 0)
                setSetting(settings[0])
        })
    }, [])

    const urlParams = new URLSearchParams(window.location.search);


    const toShowIndex = urlParams.get('index')

    return (
        <div id="wrapper">

            <Header home={true} />
            <div id="content" className="no-bottom no-top">

                <Campagin toShowIndex={toShowIndex} />

                <section id="section-about">
                    <div className="container">
                        {setting && parse(setting?.about_text + "")}
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <GalleryItems viewMore limit={3} likesVisible={false} />
                    </div>
                </section>

                {/* <GalleryPictureInPictureGrid /> */}


            </div>

            <Footer />
        </div>
    )
}

export default Home