import { HttpService } from "../http";


export class CampaignHttpService {

    static async loadCampaigns(){
        var service = new HttpService();
        var response = await service.get('/campaign/',null);
        return response;
    }

    static async loadUsers(){
        var service = new HttpService();
        var response = await service.get('/users/',null);
        return response;
    }

    static async loadCampaign(id){
        var service = new HttpService();
        var response = await service.get(`/campaign/${id}`,null);
        return response;
    }

    static async loadCampaignParticipants(campaign,page=1,status=null){

        var query = ""
        if(status!=null){
            query = `&status=${status}`
        }


        var service = new HttpService();
        var response = await service.get(`/campaign/participant/?campaign=${campaign}${query}`,null);
        return response;
    }

    static async loadCampaignParticipant(id){
        var service = new HttpService();
        var response = await service.get(`/campaign/participant/${id}`,null);
        return response;
    }


    static async saveCampaignParticipant(body) {
        const formData = new FormData();

        formData.append("campaign", body.campaign);
        formData.append("index", body.index);
        formData.append("is_active", 1);

        if (body.file) formData.append("image", body.file, body.file.name);
        if (body.image) formData.append("image", body.image);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.patch(`/campaign/participant/${body.id}`, formData, true);
        }
        else
            response = await service.post('/campaign/participant/', formData, true);

        return await response
    }


    static async acceptCampaignParticipant(id) {

        var body= {
            status : 1
        }

        var service = new HttpService();
        var response;


        if (id) {
            response = await service.patch(`/campaign/participant/${id}`, body);
        }
        return await response
    }

    
    static async saveCampaign(body) {
        const formData = new FormData();

        formData.append("name", body.name);
        formData.append("description", body.description);
        formData.append("number_of_squares", body.number_of_squares);
        formData.append("square_margin", body.square_margin);
        formData.append("square_width", body.square_width);
        formData.append("square_opacity", body.square_opacity);
        formData.append("is_active", 1);
        
        if (body.file) formData.append("image", body.file, body.file.name);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.put(`/campaign/${body.id}`, formData, true);
        }
        else
            response = await service.post('/campaign/', formData, true);

        return await response
    }


    static async deleteItem(url){
        var service = new HttpService();
        var response = await service.delete(`${url}`);
        return response;
    }

    
}

