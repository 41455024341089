import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { CampaignHttpService } from "../../../../services/campaign";
import { BaseHttpService } from "../../../../services/base";

function UserForm({ id,user=null, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const fileRef = useRef()

  useEffect(() => {
    if (id && !user) {
      BaseHttpService.loadUser(id).then(item => {
        onEditing(item)
        console.log(item)
      })
    }

    if(user){
      onEditing(user)
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    for (const key in item.profile) {
      setValue(key, item.profile[key])
    }

    setImage(item.image)

  }

  const _previewFile = (file) => {

    const objectUrl = URL.createObjectURL(file)
    setImage(objectUrl);
    setRefresh(refresh + 1)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    if(!data.first_name){
      setError("Please provide first name");
      return;
    }

    if(!data.last_name){
      setError("Please provide last name");
      return;
    }

    var body = {
      "id": id ? id : data.id,
      "first_name": data.first_name,
      "last_name": data.last_name,
      "username": data.username,
      // "file": file,
    }

    if(data.password)
      body.password = data.password;

    setDone(false)
    setLoading(true)
    BaseHttpService.saveUser(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.callback();
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const _handleFileClick = (e) => {
    e.preventDefault();
    fileRef.current.click();

  }

  const _handleFileChange = (e) => {
    var file = e.target.files[0]
        if (e.target.files){
          setFile(file)
          _previewFile(file)
        }

  }
  return (
    <div>
      <form className="mb-2" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="Username"
                    value={watch('username')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("username", { required: true })}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    {...register("password", { required: false })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="First Name"
                    value={watch('first_name')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("first_name", { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={watch('last_name')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("last_name", { required: true })}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <img src={ image ? image :  `/assets/images/default.png`} alt="Upload"  onClick={_handleFileClick} className="img-fluid" />
            <input type="file" ref={fileRef} className="hide" onChange={_handleFileChange} />
          </div>
        </div>

        <div className="form-group mt-1 mb-5">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <EditIcon /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add User"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default UserForm;
