import React, { useEffect } from 'react'
import { CampaignHttpService } from '../../../../services/campaign';
import { HttpService } from '../../../../services/http';

function CampaignParticipants() {

    const [campaignParticipants,setCampaignParticipants] = React.useState([]);
  const [loading,setLoading] = React.useState(false);
  const [refresh,setRefresh] = React.useState(0);
  const [campaigns,setCompaigns] = React.useState(null);
  const [campaign,setCampaign] = React.useState(null);
  const [campaignParticipantsCount,setCampaignParticipantsCount] = React.useState(0);

  useEffect(() => {

    _getData()

  }, [])

  
  const _getData = () => {
    setLoading(true)
    CampaignHttpService.loadCampaigns().then(campaigns => {
      setCompaigns(campaigns)
      if (campaigns) {
        setCampaign(campaigns[0])
        _getNewCampaignParticipants(campaigns[0].id)
      }
      setLoading(false)
    })
  }

  const _getNewCampaignParticipants = (campaign,page=1)=>{
    setLoading(true);
    CampaignHttpService.loadCampaignParticipants(campaign,1,0).then(data=>{
      setLoading(false);
      setCampaignParticipants(data)
      setCampaignParticipantsCount(data.length)
    })

  }

  const _deleteItem = (e,id)=>{
    e.preventDefault()
    console.log("Done")
    setLoading(true);
    setCampaignParticipants(campaignParticipants.filter(item=>item.id!=id))
    setRefresh(refresh+1)
    CampaignHttpService.deleteItem(`/campaign/participant/${id}`).then(data=>{
      setLoading(false);
    })

  }

  const _acceptItem = (e,id)=>{
    e.preventDefault()
    setLoading(true);
    setCampaignParticipants(campaignParticipants.filter(item=>item.id!=id))
    setRefresh(refresh+1)
    CampaignHttpService.acceptCampaignParticipant(id).then(data=>{
      setLoading(false);

    })

  }


  return (
    <div className='campaign-container'>
        {campaignParticipants.map(item=>(
            <div key={item.id+"cmp"} className='campaign-item'>
            <div className="image">
            <a href={`/cms/campaign/participants/${item.id}`}>
                <img src={item.avatar} alt={item.name} />
                </a>
            </div>
            <div className="caption">
                <div className="d-flex justify-content-space-between">
                    <button className="btn" onClick={(e)=>{_deleteItem(e,item.id)}}>
                        <a href="#REJECT" >
                            <i className="fa fa-minus"></i>
                        </a>
                    </button>
                    <small>{item.index}</small>
                    <button className="btn"  onClick={(e)=>{_acceptItem(e,item.id)}}>
                        <a href={`/cms/campaign/participants/${item.id}`}>
                            <i className="fa fa-plus"></i>
                        </a>
                    </button>
                    
                </div>
            </div>
            </div>
        )
        )}
    </div>
  )
}

export default CampaignParticipants