import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { BaseHttpService } from "../../../../services/base";
import TextEditor from "../widgets/TextEditor";

function SettingForm({ props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const fileRef = useRef()

  useEffect(() => {
      BaseHttpService.loadSettings().then(items => {
        if(items != null){
          onEditing(items[0])
        }
      })
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setImage(item.image)

  }

  const _previewFile = (file) => {

    const objectUrl = URL.createObjectURL(file)
    setImage(objectUrl);
    setRefresh(refresh + 1)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;


    var body = {
      "id": data.id,
      "site_title": data.site_title,
      "site_description": data.site_description,
      "about_text": data.about_text,
      "contact_text": data.contact_text,
      "privacy_text": data.privacy_text,
      "terms_text": data.terms_text,
      "cookie_text": data.cookie_text,
      "file": file,
    }

    setDone(false)
    setLoading(true)
    BaseHttpService.saveSetting(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.callback();
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const _handleFileClick = (e) => {
    e.preventDefault();
    fileRef.current.click();

  }

  const _handleFileChange = (e) => {
    var file = e.target.files[0]
        if (e.target.files){
          setFile(file)
          _previewFile(file)
        }

  }
  return (
    <div>
      <form className="mb-5" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="Site Title"
                    value={watch('site_title') ?? ""}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("site_title", { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextEditor onChange={(value)=>{setValue("site_description",value)}} placeholder="Site description" data={watch("site_description")} />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextEditor onChange={(value)=>{setValue("about_text",value)}} placeholder="About" data={watch("about_text")} />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextEditor onChange={(value)=>{setValue("contact_text",value)}} placeholder="Contact text" data={watch("contact_text")} />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextEditor onChange={(value)=>{setValue("privacy_text",value)}} placeholder="Privacy text" data={watch("privacy_text")} />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextEditor onChange={(value)=>{setValue("cookie_text",value)}} placeholder="Cookie text" data={watch("cookie_text")} />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <TextEditor onChange={(value)=>{setValue("terms_text",value)}} placeholder="Terms text" data={watch("terms_text")} />
                </div>
              </div>


            </div>
          </div>
          <div className="col-md-4">
            <img src={ image ? image :  `/assets/images/default.png`} alt="Upload"  onClick={_handleFileClick} className="img-fluid" />
            <input type="file" ref={fileRef} className="hide" onChange={_handleFileChange} />
          </div>
        </div>

        <div className="form-group mt-5 mb-5">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <EditIcon /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Gallery"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default SettingForm;
