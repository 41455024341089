import * as React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import DeleteDialog from '../../shared/DeleteDialog';
import { BaseHttpService } from '../../../../services/base';

const table = "gallery"
export default function GalleryList({...props}) {
  const [deleteDialogIsOpen,setDeleteDialogIsOpen] = React.useState(false);
  const [selectedId,setSelectedId] = React.useState(0);
  const [data,setData] = React.useState([]);
  const [count,setCount] = React.useState(0);
  const [loading,setLoading] = React.useState(false);
  const [refresh,setRefresh] = React.useState(0);
  
  useEffect(() => {

    _getData()

  }, [])

  const _getData = (page=1)=>{

    BaseHttpService.loadGalleries(page).then(data=>{
      setData(data)
      setCount(data.length)
    })

  }


  const _handleDelete = (e,id) => {
    console.log()
    setSelectedId(id);
    setDeleteDialogIsOpen(true)
    setRefresh(refresh+1)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(item=>item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh+1)
  }



  return (
    <>
    <div className='box'>
        <div className="box-header">
        <h5>Galleries</h5>
          <div className="btns">
            <Link to="/cms/gallery/add" className="btn"><i className="fa fa-plus"></i></Link>
          </div>
        </div>
        <div className="box-body d-flex">
          {data.map(item=>(
            <div key={item.id+"cmp"} className='campaign-item'>
              <div className="image">
                <Link to={`/cms/gallery/${item.id}`}>
                  <img src={item.image} alt={item.name} />
                </Link>
              </div>
              <div className="caption">
                <a href={`/cms/gallery/${item.id}`}>{item.id} {item.name}</a>
                <div className="btns">
                <button className="btn">
                        <Link to={`/cms/gallery/${item.id}`}>
                            <i className="fa fa-edit"></i>
                        </Link>
                    </button>
                    <button className="btn">
                        <a href="#Delete" onClick={(e)=>{_handleDelete(e,item.id)}}>
                            <i className="fa fa-trash"></i>
                        </a>
                    </button>
                </div>
              </div>
            </div>
          )
          )}
          
        </div>
    </div>
    
          <DeleteDialog
          url={`/base/gallery/${selectedId}`}
          isOpen={deleteDialogIsOpen}
          key={"mdl"+deleteDialogIsOpen+selectedId}
          setOpen={setDeleteDialogIsOpen}
          notifyDone={_onDeleteDone}
        />
    </>
  );
}