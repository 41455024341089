import React, { useContext, useEffect, useState } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import SubHeader from '../../components/shared/SubHeader'
import { BaseHttpService } from '../../services/base'
import parse from 'html-react-parser'

function About() {

  const [setting, setSetting] = useState()

  useEffect(() => {
    BaseHttpService.loadSettings().then(settings => {
      if(settings.length>0)
      setSetting(settings[0])
    })
  }, [])

return (
  <div id="wrapper">

    <Header autoShow={false} />
    <div id="content" className="no-bottom ">
    <SubHeader title="About" />
     <div className="container">
      <section>

      {setting && parse(setting?.about_text+"")}
      </section>
     </div>

    </div>

    <Footer />
  </div>
)
}

export default About