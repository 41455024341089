import React from 'react'
import CampaignListSquare from '../../components/list/campaign/CampaignListSquare'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'

function Campaign() {


  return (
    <div id="wrapper">
        <Header />

        <div id="content" className='container pb-0'>
            <div className="row">
                {/* <MainMenu /> */}
                <div className="col-md-12">
                   <CampaignListSquare />
                </div>
            </div>

        </div>

        <Footer />
    </div>
  )
}

export default Campaign