import React, { useContext, useEffect } from 'react'
import { BaseHttpService } from '../../services/base';
import CustomModal from '../../components/modals/CustomModal'
import GalleryItemView from './GalleryItemView';
import { Favorite, FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import GalleryLike from './GalleryLike';
import { Link } from 'react-router-dom';
import { flexbox } from '@mui/system';
import LoginModal from '../modals/LoginModal';
import { HttpService } from '../../services/http';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translate } from '../../utils';



function GalleryItems({ gallery, likesVisible = true, viewMore = false, limit = 99 }) {

    const [data, setData] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const [galleryItemModalIsOpen, setGalleryItemModalIsOpen] = React.useState(false);
    const [selectedGalleryItem, setSelectedGalleryItem] = React.useState(0);
    const [loginModalIsOpen, setLoginModalIsOpen] = React.useState(false);

    const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}

    useEffect(() => {

        _getData()

    }, [])

    const _getData = (page = 1) => {

        BaseHttpService.loadGalleryItems(gallery).then(data => {
            setData(data)
            setCount(data.length)
        })

    }

    const _showGalleryItem = (e, galleryItem) => {
        e.preventDefault();
        setSelectedGalleryItem(galleryItem)
        setGalleryItemModalIsOpen(true)
        setRefresh(refresh + 1)
    }

    const _likeChanged = (value, id = selectedGalleryItem.id) => {
        setLoading(true)
        var user = HttpService.getUser()
        setLoading(false)

        if (!user) {
            setLoginModalIsOpen(true)
            return;
        }
        else{
            _performLikeChanged()
        }
        
    }

    const _performLikeChanged = (value, id = selectedGalleryItem.id) => {
        let temp = data.map((item) => {
            if (item.id === id)
                return { ...item, is_liked: value, likes_count: value ? item.likes_count++ : item.likes_count-- }
            return item
        })
        setData(temp)
        setRefresh(refresh + 1)
    }

    const _next = () => {
        let _index = data.findIndex(item => item.id === selectedGalleryItem.id) + 1
        if (_index === data.length) _index = 0
        setSelectedGalleryItem(data[_index])
        setRefresh(refresh + 1)
    }
    const _previous = () => {

        let _index = data.findIndex(item => item.id === selectedGalleryItem.id) - 1
        if (_index < 0) _index = data.length - 1
        setSelectedGalleryItem(data[_index])
        setRefresh(refresh + 1)
    }


    function _wGalleryItem(item, index) {
        return (
            <div key={item.id + "gip"} className="col-md-4 pointer text-middle mb-4 text-light wow fadeInRight" data-wow-delay={`${index * 0.2}s`}>
                <div className="shadow-soft bg-cover gallery-item picframe">
                    <img src={`${item.image}`} onClick={(e) => { _showGalleryItem(e, item) }} alt="" />
                    <div className="padding40 overlay40" >
                        <h3 onClick={(e) => { _showGalleryItem(e, item) }}>{item.name}</h3>
                        {likesVisible && (
                            <GalleryLike key={item.id + "gld"} likeChanged={(value) => {
                                _likeChanged(value, item.id)
                            }} galleryItem={item} />
                        )}


                        {/* <p>{item.description}</p> */}
                        {/* <a href="service-3.html" className="btn-line btn-fullwidth">More Details</a> */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="row" >
                {data.map((item, index) => index < limit ? _wGalleryItem(item, index) : null)}
            </div>
            {viewMore && (
                <div className="mt-4 center wow fadeIn" data-wow-delay={`${1.2}s`} >
                    <Link to="/gallery/" className="btn-line multi">{_t("View more")}</Link>
                </div>
            )}
            <CustomModal big className="p-0" key={"gim" + galleryItemModalIsOpen} setOpen={(value) => setGalleryItemModalIsOpen(value)}
                open={galleryItemModalIsOpen}  >

                <GalleryItemView galleryItem={selectedGalleryItem} likeChanged={_likeChanged} next={_next} previous={_previous} />

            </CustomModal>

            <LoginModal key={"lm" + loginModalIsOpen}
                onLoggedIn={()=>{ setLoginModalIsOpen(false) }}
                isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

            />
        </>
    )
}


export default GalleryItems