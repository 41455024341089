import { PersonAdd } from '@mui/icons-material';
import React, { useRef, useState } from 'react'
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { CampaignHttpService } from '../../../services/campaign';
import { translate } from '../../../utils';

export default function UploadCampaignImageButton({ onUpload, ...props }) {
    const inputFileRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [terms_accepted, setTermsAccepted] = useState(false);

    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    const handleFileChange = (e) => {
        var selectedFiles = e.target.files;

        if (selectedFiles.length) {
            let file_size = selectedFiles[0].size;
            if (file_size < 20 * (2 ** 20)) { // 20 MB
                _saveCampaignParticipant(selectedFiles[0])
            }
        }


    }



    const selectFile = () => {
        if (terms_accepted) {
            inputFileRef.current.click();
        }
        else {
            setError(_t("Please accept the terms and conditions"));
        }
    };

    const _saveCampaignParticipant = (file) => {
        if (loading) return;

        setLoading(true);
        var body = {
            "id": props.participantId,
            "index": props.index,
            "campaign": props.campaign,
            "file": file
        }
        CampaignHttpService.saveCampaignParticipant(body).then(response => {
            setLoading(false);
            if (response?.id) {
                onUpload(response)
            }
        });
    };

    return (
        <div>
            {loading && (
                <button className="btn p-2" type="button" disabled>
                    <span className="spinner-grow text-warning spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </button>
            )}
            {!loading && (
                <>

                    <div className="d-flex justify-content-center " onClick={() => { selectFile() }}>
                        <p className="campaign_index" title="">
                            {/* <i className="fa fa-user-plus"></i> */}
                            <PersonAdd />
                        </p>
                    </div>
                    <div className="mb-3 text-center lan-kfont lan-rtl">
                        {props.index + " "+_t(`is your JINA index`)}
                    </div>
                    <div className="terms">
                        <input onChange={(e)=>{ setError(false); setTermsAccepted(!terms_accepted) }}  id="chkterms" type="checkbox" />
                        <label htmlFor="chkterms" >
                            {_t(`I hereby accept the terms and conditions`)}
                        </label>
                    </div>
                    {error && (
                        <>
                            <div className="alert alert-danger mt-4">
                                {error}
                            </div>
                        </>
                    )}
                    {/* <input type="button" value={props?.title ? props.title : "Select Image ..."} onClick={() => { selectFile() }} className={props.className ? props.className : "btn btn-line"} /> */}
                    <input type="file" ref={inputFileRef} onChange={handleFileChange} className="hide" />
                </>
            )}

        </div>
    )
}
