import React, { useState } from 'react'
import ResponsiveMenu from './ResponsiveMenu';

function MobileMenu(props) {

    const [show_mobile_menu, setShowMobileMenu] = useState(null);

    const openMobileMenu = () => {
        setShowMobileMenu(true);
      };
    
      const hideMobileMenu = () => {
        setShowMobileMenu(false);
      };


  return (
    <div className='mobile-menu-container'>
       <button onClick={openMobileMenu} className="btn btn-dark " ><i className='fa fa-bars'></i></button>
       <ResponsiveMenu
        hideMenu={hideMobileMenu}
        show={show_mobile_menu}
        onAdd={props.onAdd}
        onSearch={props.onSearch}
      />
    </div>
  )
}

export default MobileMenu