import React, { useContext, useEffect, useState, } from 'react'
import { UserContext } from '../../contexts/UserContext';


const ResponsiveMenu = ({ show, hideMenu, ...props }) => {

	const { authenticate } = useContext(UserContext);

	const _search = (e) => {
		e.preventDefault()
		if (props.onSearch) props.onSearch()
		hideMenu()
	}

	const _add = (e) => {
		console.log()
		e.preventDefault()
		if (props.onAdd) props.onAdd()
		hideMenu()
	}


	return (
		<nav className={`responsive-menu ${show ? "active" : ""}`} >
			<a href="#close-menu"  onClick={hideMenu} className="pull-right color-white" >Close</a>
			<ul className="nav nav-list flex-column">
				<li className="nav-item">
					<a className="nav-link" href={`/`}><i className='fa fa-home mr-1'></i>  Home</a>
				</li>

				<li className="nav-item">
					<a className="nav-link" href="search" onClick={_search} ><i className='fa fa-search mr-1'></i> Search</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="add" onClick={_add} ><i className='fa fa-plus mr-1'></i> Add</a>
				</li>
				<li className="nav-item">
					{authenticate ? (
						<a href="/?signout=1"  className="nav-link"><i className='fa fa-sign-out mr-1'></i> Sign out</a>
					) : (
						<a href="/?login" onClick={_add}  className="nav-link"><i className='fa fa-sign-in mr-1'></i> Login</a>
					)}
				</li>

			</ul>
		</nav>

	)

}
export default ResponsiveMenu
