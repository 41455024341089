import React from 'react'
import AdminLoginForm from '../../components/forms/user/AdminLoginForm'

function Login() {
  return (
    <div id="wrapper " className='min-h-100vh'>
        <div id="content">
            <div className="row">
                <div className="d-flex flex-column align-items-center justify-content-center ">
                        <p className='cjit'><b>CJIT</b> Admin</p>
                            <div className="col-md-3">
                                <AdminLoginForm  />
                            </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login