import React, { useRef, useState } from 'react'
import { BaseHttpService } from '../../../services/base';
import { CampaignHttpService } from '../../../services/campaign';

export default function EditCampaignImageButton({ onUpload,onDelete, ...props }) {
    const inputFileRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        var selectedFiles = e.target.files;

        if (selectedFiles.length) {
            let file_size = selectedFiles[0].size;
            if (file_size < 20 * (2 ** 20)) { // 20 MB
                _saveCampaignParticipant(selectedFiles[0])
            }
        }


    }



    const selectFile = () => {
        inputFileRef.current.click();
    };

    const _saveCampaignParticipant = (file) => {
        if (loading) return;

        setLoading(true);
        var body = {
            "id": props.participantId,
            "index": props.index,
            "campaign": props.campaign,
            "file": file
        }
        CampaignHttpService.saveCampaignParticipant(body).then(response => {
            setLoading(false);
            if (response?.id) {
                onUpload(response)
            }
        });
    };

    const _delete = (file) => {
        if (loading) return;

        setLoading(true);
        var url = `/campaign/participant/${props.participantId}`;

        BaseHttpService.deleteItem(url).then(response => {
            setLoading(false);
            onDelete()
        });
    };

    return (
        <div>
            {loading && (
                <button className="btn p-2" type="button" disabled>
                    <span className="spinner-grow text-warning spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                </button>
            )}
            {!loading && (
                <div className='edit-photo-container'>
                        <i className="fa fa-edit" onClick={() => { selectFile() }}></i>

                        <i className="fa fa-trash " onClick={() => { _delete() }}></i>

                    <input type="file" ref={inputFileRef} onChange={handleFileChange} className="hide" />
                </div>
            )}

        </div>
    )
}
