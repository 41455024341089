import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import { useParams } from "react-router-dom";
import SettingForm from '../../components/forms/settings/SettingForm'

function Settings() {


  return (
    <div id="wrapper">
      <Header />
      <div id="content" className='container pb-0'>
        <div className='box'>
          <div className="box-header mb-5">
            <h5>Settings</h5>
            <div className="btns">
            </div>
          </div>
          <div className="box-body">
            <div className="row">
              {/* <MainMenu /> */}
              <div className="col-md-12">
                <SettingForm  />
              </div>
            </div>
          </div>
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default Settings