import React from 'react'
import { Link } from 'react-router-dom'

function SubHeader({title="",parent="Home", parentTo="/" }) {
  return (
    <section id="subheader" style={{background: 'url("assets/images/subheader.jpg")' }} className="bg-cover" >
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1>{title}</h1>
                <ul class="crumb">
                    <li><Link href={parentTo}>{parent}</Link></li>
                    <li class="sep">/</li>
                    <li>{title}</li>
                </ul>
            </div>
        </div>
    </div>
</section>
  )
}

export default SubHeader