import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { Error } from '@mui/icons-material';
import { HttpService } from '../../../services/http';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({ url, isOpen, method = "delete", body, setOpen, notifyDone }) {

  const handleDelete = () => {
    // setOpen(false);
    var http = new HttpService()
    if (method === "delete")
      http.delete(url).then(() => {
        if (notifyDone !== undefined)
          notifyDone()
      })
    else if (method === "patch") {
      http.patch(url, body).then(() => {
        if (notifyDone !== undefined)
          notifyDone()
      })
    }
  };

  const handleClose = () => {
    // console.log("delete")
    setOpen(false);
  };



  return (
    <div>

      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            handleDelete()
          }
          if (e.key == "Escape") {
            handleClose()
          }
        }}
      // onKeyDown={(e)=>{
      //   if (e.key === 'Enter') {

      //   }
      //   }}
      >
        <DialogTitle>
          <div className="dialog-icon">
            <Error style={{ fontSize: '58px' }} />
          </div>
          <div className='dialog-delete-message'>
            {"Are you sure you want to delete this item ?"}
          </div>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}