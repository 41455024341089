import React, { useEffect } from 'react'
import { Utils } from '../../utils';
import GalleryLike from './GalleryLike';
import SocialShareIcons from './SocialShareIcons';


function GalleryItemView({ galleryItem, likeChanged ,...props}) {

    
    const [animationClass, setAnimationClass] = React.useState("");

    useEffect(() => {

    }, [])

    const _resetAnimation = ()=>{
        setTimeout(function(){
            setAnimationClass("")
        }, 500);
    }

    const _next = ()=>{
        setAnimationClass("fadeInRight")
        props?.next()
        _resetAnimation()
    }

    const _previous = ()=>{
        setAnimationClass("fadeInLeft")
        props?.previous()
        _resetAnimation()
    }



    return (
        <>
            <div className="">
                <div className="gallery-arrows">
                    <div className="arrow next " onClick={_next} >
                        <i className="fa fa-chevron-right"></i>
                    </div>
                    <div className="arrow previous" onClick={_previous}>
                        <i className="fa fa-chevron-left"></i>
                    </div>
                </div>                
                <div className={`text-middle animated ${animationClass}    gallery-item-view border-radius-3 text-light`} >
                    <img src={galleryItem.image} alt={galleryItem.name} className="full-width border-radius-3" />
                    <div className="caption">
                        <div>
                            <h3 className='mb-1 text-shadow-1'>{galleryItem.name}</h3>
                            <div className="">
                                <div className='gallery-item-description' dangerouslySetInnerHTML={{ __html: Utils.nl2br(galleryItem.description) }} />
                            </div>
                        </div>
                        <SocialShareIcons url={`https://jina.nu/gallery?art=${galleryItem.id}&name=${(galleryItem?.name + "").replace(/ /g, "-")}`} />
                        <GalleryLike
                            key={"gl" + galleryItem}
                            liked={galleryItem.is_liked}
                            likeChanged={likeChanged}
                            galleryItem={galleryItem} />

                    </div>
                </div>
            </div>
        </>
    )
}

export default GalleryItemView