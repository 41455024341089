import { HttpService } from "../services/http";
import Constants from "./constants";


export function translate(key,translations){
    if(!translations) return key;
    let _language = Utils.getCurrentLanguageName()
    let _translate = translations.find(item=>item.key.toLowerCase()===key.toLowerCase());
    if(_translate){
        let _translate_value = _translate.values.find(item=>item.language.abbreviation.toLowerCase()===_language);
        if(_translate_value){
            return _translate_value.value;
        }
    
    }


    return key;
}

export class Utils {

    Utils(){
        
    }

     
    static getCurrentLanguageName = function () {
        let _language_name = window.localStorage.getItem(Constants.LanguageTokenName)
        return _language_name ?? "en";
    }

    static pageUrl = function () {
        return window.location.origin + window.location.pathname;
    }

    
    
    static setCurrentLanguageName = function (languageName) {
        window.localStorage.setItem(Constants.LanguageTokenName,languageName.toLowerCase())
    } 

    static clearUrl = function () {
        window.history.pushState({}, document.title, window.location.pathname);
    }

    static getUrlParameter = function (parameter , defaultValue=null) {
        const urlParams = new URLSearchParams(window.location.search);
        let _res = urlParams.get(parameter)
        return _res ? _res : defaultValue;
    }

    static delay = (miliseconds) => {
        return new Promise(resolve => setTimeout(resolve, miliseconds));
    }


    static MB = function (file_size) {
        if (!file_size) return "";
        var _size;
        if (file_size < 1024 * 1024) {
            _size = Math.floor(file_size / 1024) + ' KB';
        } else {
            _size = Math.floor(file_size / (1024 * 1024)) + ' MB';
        }
        return _size;
    }

    static nl2br(str, is_xhtml) {
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    static getDate = (dateString) => {
        var dateObject = new Date(dateString);
        return dateObject.toDateString();
    };

    static isAdmin = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "admin") {
            return true;
        } else return false
    }

    static isParticipant = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "participant") {
            return true;
        } else return false
    }

    

}

