import { HttpService } from "../http";


export class BaseHttpService {
    
    static async loadUser(id){
        var service = new HttpService();
        var response = await service.get(`/api/user/${id}`,null);
        return response;
    }

    static async saveUser(body) {
        const formData = new FormData();

        // formData.append("first_name", body.name);
        // formData.append("last_name", body.name);
        // formData.append("username", body.description);
        // // formData.append("is_active", 1);
        
        // if (body.file) formData.append("image", body.file, body.file.name);
        // if (body.password) formData.append("password", body.password);

        var service = new HttpService();
        var response;

        console.log(body);


        if (body.id) {
            response = await service.put(`/api/user/${body.id}`, body);
        }
        else
            response = await service.post('/api/user/', body);

        return await response
    }

    static async deleteItem(url){
        var service = new HttpService();
        var response = await service.delete(`${url}`);
        return response;
    }

    static async loadGalleries(){
        var service = new HttpService();
        var response = await service.get('/base/gallery/',null);
        return response;
    }

    static async loadGallery(id){
        var service = new HttpService();
        var response = await service.get(`/base/gallery/${id}`,null);
        return response;
    }

    static async loadGalleryItems(gallery=null,page=1){

        var query = `&page=${page}`

        var service = new HttpService();
        var response = await service.get(`/base/galleryitem/?gallery=${gallery}${query}`,null);
        return response;
    }

    static async loadGalleryItem(id){
        var service = new HttpService();
        var response = await service.get(`/base/galleryitem/${id}`,null);
        return response;
    }

    static async saveGallery(body) {
        const formData = new FormData();

        formData.append("name", body.name);
        formData.append("description", body.description);
        
        if (body.file) formData.append("image", body.file, body.file.name);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.put(`/base/gallery/${body.id}`, formData, true);
        }
        else
            response = await service.post('/base/gallery/', formData, true);

        return await response
    }

    static async saveGalleryItem(body) {
        const formData = new FormData();

        formData.append("gallery", body.gallery);
        formData.append("name", body.name);
        formData.append("description", body.description);
        
        if (body.file) formData.append("image", body.file, body.file.name);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.put(`/base/galleryitem/${body.id}`, formData, true);
        }
        else
            response = await service.post('/base/galleryitem/', formData, true);

        return await response
    }

    static async likeGalleryItem(body) {

        var service = new HttpService();
        var response;

        response = await service.post('/base/galleryitem/like/', body);

        return await response
    }



    ////////////////////////////////////////////////////////////////


    
    static async loadSettings(){
        var service = new HttpService();
        var response = await service.get('/base/setting/',null);
        return response;
    }

    static async loadTranslations(){
        var service = new HttpService();
        var response = await service.get('/base/language/translate/',null);
        return response;
    }

    
    static async saveSetting(body) {
        const formData = new FormData();

        formData.append("site_title", body.site_title);
        formData.append("site_description", body.site_description);
        formData.append("about_text", body.about_text);
        formData.append("contact_text", body.contact_text);
        formData.append("privacy_text", body.privacy_text);
        formData.append("terms_text", body.terms_text);
        formData.append("cookie_text", body.cookie_text);
        
        if (body.file) formData.append("logo", body.file, body.file.name);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.put(`/base/setting/${body.id}`, formData, true);
        }
        else
            response = await service.post('/base/setting/', formData, true);

        return await response
    }

   
}

