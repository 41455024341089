import { createContext, useEffect, useState } from "react";
import { BaseHttpService } from "../services/base";
import { HttpService } from "../services/http";
import { Utils } from "../utils";
import Constants from "../utils/constants";

export const LanguageContext = createContext();

export const LanguageContextProvider = (props) => {

  const [language, setLanguage] = useState(Utils.getCurrentLanguageName());
  const [translations, setTranslations] = useState(null);

  let lan = Utils.getUrlParameter("lan");
  let currentLanguage = Utils.getCurrentLanguageName();

  if (lan && lan !== currentLanguage) {
    setLanguage(lan)
    currentLanguage= lan
    Utils.setCurrentLanguageName(lan);
  }


  if (['en'].includes(currentLanguage)) {
    document.getElementsByTagName("html")[0].classList.remove(Constants.LANGUAGEClassName);
  }
  else if (['fa','ku'].includes(currentLanguage)) 
    document.getElementsByTagName("html")[0].classList.add(Constants.LANGUAGEClassName);




  useEffect(() => {
    BaseHttpService.loadTranslations().then((data) => {
      // console.log(data)
      setTranslations(data)
    })
  }, []);

  return (
    <LanguageContext.Provider value={{ setLanguage, language, translations }}>
      {props.children}
    </LanguageContext.Provider>
  );
};
