import React, { useContext } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { HttpService } from '../../services/http';
import { translate, Utils } from '../../utils';
import LoginModal from '../modals/LoginModal';
import MetaTags from './MetaTags';

function Header({ autoShow = true, home = false, onLoggedIn = null }) {
	var user = HttpService.getUser()

	const { setLanguage , translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}
	const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);



	return (
		<header className={`${autoShow ? "autoshow" : ""} hide-on-small`}>
			<MetaTags />
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div id="logo">
							<Link to="/">
								<img className="logo" src="assets/images/logo.png" alt="" />
							</Link>
						</div>
						<span id="menu-btn"></span>
						<nav className="md-flex">
							<ul id="mainmenu">
								<li><a href={home ? "#section-home" : "/"} className={home ? "scroll-to" : ""}>{_t("Home")}<span></span></a></li>
								<li><a href={home ? "#section-about" : "/about"} className={home ? "scroll-to" : ""}>{_t("About")}<span></span></a></li>
								{/* <li><a href="/campaigns">Campaigns<span></span></a></li> */}
								<li><a href="/gallery">{_t("Gallery")}<span></span></a></li>
								
								{/* <li><a href="">|<span></span></a></li> */}
								
								{user && (
									<li><a href="/?signout=1">{_t("Logout")}<span></span></a>
									</li>
								)}
								{!user && (
									<li><a href="/?login" onClick={(e) => {
										e.preventDefault();
										setLoginModalIsOpen(true);
									}

									} >{_t("Login")}<span></span></a>

									</li>
								)}


								<li className='ml-5 language-menu-item'><Link to="?lan=en"> <i className="fa fa-globe"></i> {Utils.getCurrentLanguageName()}</Link>
									<ul>
										<li onClick={(e)=>{ e.preventDefault(); setLanguage("ku") }} className="kfont"><Link to={`/?lan=ku`}>کوردی</Link></li>
										<li onClick={(e)=>{  e.preventDefault(); setLanguage("fa") }} className="kfont"><Link to={`/?lan=fa`}>فارسی</Link></li>
										<li onClick={(e)=>{  e.preventDefault(); setLanguage("en") }} ><Link to={`/?lan=en`}>English</Link></li>
									</ul>
								</li>
								{/* <li><a href="/contact">Contact<span></span></a></li> */}
			

							</ul>
						</nav>

					</div>

				</div>


			</div>
			{/* <LoginModal key={"lm" + loginModalIsOpen}
					isOpen={loginModalIsOpen} 
					setOpen={setLoginModalIsOpen}
					  /> */}
			<LoginModal key={"lm" + loginModalIsOpen}
				onLoggedIn={onLoggedIn}
				isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

			/>
		</header>
	)
}

export default Header