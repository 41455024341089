import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import { useParams } from "react-router-dom";
import GalleryForm from '../../components/forms/gallery/GalleryForm'
import { BaseHttpService } from '../../../services/base'
import DeleteDialog from '../../components/shared/DeleteDialog'
import CustomModal from '../../../components/modals/CustomModal'
import GalleryItemForm from '../../components/forms/gallery/GalleryItemForm'

function GalleryItems({gallery}) {

  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogIsOpen,setDeleteDialogIsOpen] = React.useState(false);
  const [galleryItemModalIsOpen,setGalleryItemModalIsOpen] = React.useState(false);
  
  const [selectedId,setSelectedId] = React.useState(0);
  const { id } = useParams();
  const [refresh,setRefresh] = React.useState(0);
  
  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    setLoading(true);
    BaseHttpService.loadGalleryItems(gallery).then((items) =>{
        setData(items)
    });
  }


  const _handleDelete = (e,id) => {
    setSelectedId(id);
    setRefresh(refresh+1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(campaign=>campaign.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh+1)
  }

  const _editGalleryItem = (e,item)=>{ 
    e.preventDefault();
    setSelectedId(item.id); 
    setGalleryItemModalIsOpen(true)
    setRefresh(refresh+1)
  }



  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>Pictures</h5>
        <div className="btns">
          <button className="btn btn-icon" onClick={()=>{ setSelectedId(null); setGalleryItemModalIsOpen(true) }}><i class="fa fa-plus "></i></button>
        </div>
      </div>
      <div className="box-body d-flex flex-wrap">
        {data?.map(item=>(
            <div key={item.id+"cmp"} className='campaign-item'>
              <div className="image">
                  <img src={item.image} alt={item.name} />
              </div>
              <div className="caption">
                <div className="btns">
                <button className="btn" onClick={(e)=>{_editGalleryItem(e,item)}}>
                        <a href="#edit"  >
                            <i className="fa fa-edit"></i>
                        </a>
                    </button>
                    <button className="btn" onClick={(e)=>{e.preventDefault(); _handleDelete(e,item.id)}}>
                        <a href="#delete" >
                            <i className="fa fa-trash"></i>
                        </a>
                    </button>
                </div>
              </div>
            </div>
          )
          )}
      </div>

      <CustomModal className="" key={galleryItemModalIsOpen} setOpen={(value) => setGalleryItemModalIsOpen(value)}
        open={galleryItemModalIsOpen} title={`Add picture to gallery`} >
        
          <GalleryItemForm key={`gmd${selectedId}`} id={selectedId} 
          onEditDone={(galleryItem)=>{
            let index = data.findIndex(item => item.id===galleryItem.id);
            if(index>0){
              data[index].image = galleryItem.image;
            }
            else{
              data.push(galleryItem);
            }
            setData(data)
          }}
            gallery={id} />

      </CustomModal>

      <DeleteDialog
          url={`/base/galleryitem/${selectedId}`}
          isOpen={deleteDialogIsOpen}
          key={"mdl"+deleteDialogIsOpen+selectedId}
          setOpen={setDeleteDialogIsOpen}
          notifyDone={_onDeleteDone}
        />
    </div>
  )
}

export default GalleryItems