import React from 'react'

function Footer() {
  return (
    <footer className=' cms-footer'>
            <div className="subfooter mt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            &copy; Copyright 2022 - Jina.nu by <span className="id-color">CJIT</span>
                        </div>
                        <div className="col-md-6 text-right">
                            <div className="social-icons">
                                <a href="#"><i className="fa fa-facebook fa-lg"></i></a>
                                <a href="#"><i className="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i className="fa fa-rss fa-lg"></i></a>
                                <a href="#"><i className="fa fa-google-plus fa-lg"></i></a>
                                <a href="#"><i className="fa fa-skype fa-lg"></i></a>
                                <a href="#"><i className="fa fa-dribbble fa-lg"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#" id="back-to-top"></a>
        </footer>
  )
}

export default Footer