import React, { useContext } from 'react'
import Galleries from '../../components/gallery/Galleries'
import Campagin from '../../components/shared/Campagin'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'

function GalleryPage() {


  return (
    <div id="wrapper">

        <Header autoShow={false} />
        <div id="content" className="no-bottom ">
            
            <Galleries />

			</div>

        <Footer />
        </div>
  )
}

export default GalleryPage