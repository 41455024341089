import React, { useContext, useEffect, useState } from 'react'
import { CampaignHttpService } from '../../services/campaign';
import LoginModal from '../modals/LoginModal';
import RegisterModal from '../modals/RegisterModal';
import { UserContext } from "../../contexts/UserContext";
import ParticipateModal from '../modals/campaign/ParticipateModal';
import ParticipantViewModal from '../modals/campaign/ParticipantViewModal';
import SearchCampaignModal from '../modals/campaign/SearchCampaignModal';
import MobileMenu from './MobileMenu';
import { Utils } from '../../utils';
import { HttpService } from '../../services/http';
import { useMemo } from 'react';


export default function Campagin(props) {

  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [participateModalIsOpen, setParticipateModalIsOpen] = useState(false);
  const [participantViewModalIsOpen, setParticipantViewModalIsOpen] = useState(false);
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  const [already_participated, setAlreadyParticipated] = useState(false);

  const [numberOfSquares, setNumberOfSquares] = useState(2500);
  const [squareMargin, setSquareMargin] = useState("4px");
  const [squareWidth, setSquareWidth] = useState("1%");
  const [squareOpacity, setSquareOpacity] = useState("0.15");
  const [startIndex, setStartIndex] = useState(0);
  

  const [loading, setLoading] = useState(false);
  const [squaresAreVisible, setSquaresAreVisible] = useState(true);


  const [campaigns, setCompaigns] = useState(null);
  const [participants, setParticipans] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [toAnimateIndex, setToAnimateIndex] = useState(null);

  const [campaign, setCompaign] = useState([]);

  var user = HttpService.getUser()

  const [user_image, setUserImage] = useState("");


  const shouldLogin = Utils.getUrlParameter('login')

  useEffect(() => {
    _getData()

    if (shouldLogin != null || props.login) {
      setLoginModalIsOpen(true)
      Utils.clearUrl()
    }
  }, [])

  const _getData = () => {
    setLoading(true)

    if (participants?.length > 0) return;

    CampaignHttpService.loadCampaigns().then(campaigns => {
      setCompaigns(campaigns)
      if (campaigns) {
        let _campaign = campaigns[0]
        setCompaign(_campaign)

        // ? should I set states seperately ? or just use campaign data ? 

        _setSquareOptions(_campaign)

        _getCampaignParticipants(_campaign.id)
      }
      setLoading(false)
    })
  }

  const _setSquareOptions = (campaign) => {
    setNumberOfSquares(campaign.number_of_squares ?? 2500)
    setSquareMargin(campaign.square_margin ?? "4px")
    setSquareOpacity(campaign.square_opacity)
    setSquareWidth(campaign.square_width)
  }

  const _onLoggedIn = (user) => {
    console.log("you logged in successfully")

    setLoginModalIsOpen(false)

    var participant = _getParticipantByIndex(selectedIndex)

    if (participant) {
      // View the participant details in a modal
      setParticipantViewModalIsOpen(true)
    }
    else {
      if (selectedIndex) {
        setParticipateModalIsOpen(selectedIndex);
      }
      else {
        setUserImage(user?.image)
        _onAddNew()
      }
    }
  }

  const _handleSquareClick = (index) => {
    setSelectedIndex(index);
    setToAnimateIndex(-1)


    var participant = _getParticipantByIndex(index)


    if (participant) {
      // View the participant details in a modal
      setParticipantViewModalIsOpen(true)
    }
    else {
      // square is empty and user can participate in campaign
      user = HttpService.getUser()
      if (!user) {
        setLoginModalIsOpen(true);
      }
      else {
        if (already_participated) {
          setSelectedIndex(already_participated.index)
          setParticipantViewModalIsOpen(true);
        }
        else {
          setSelectedIndex(index)
          setParticipateModalIsOpen(index);
        }
      }
    }


  }

  const _getCampaignParticipants = (campaign) => {

    if (!campaign) return null;
    CampaignHttpService.loadCampaignParticipants(campaign).then(participants => {
      setParticipans(participants)

      if (participants?.length > 0 && user) {
        var me = participants.find(participant => participant.user.id === user?.id);
        if (me) {
          console.log(me)
          setAlreadyParticipated(me)
        }
      }

      // Show Shared index,
      if (participants && props.toShowIndex) {
        let index = parseInt(props.toShowIndex)

        if(index===undefined) index = 0;

        if(index > 1000) {
          setStartIndex(index-1000)
        }
        else{
          setStartIndex(0)
        }

        // var participant = participants.find(item => item.index == index)
        // _showIndex(participant, index);
        _animateIndex(index)
      }

    })
  }

  const _showIndex = (participant, index) => {
    setSelectedIndex(index)

    if (participant) {
      setParticipantViewModalIsOpen(true)
    }
  }


  const _getParticipantByIndex = (index) => {
    if (!participants) return null;




    var participant = participants.find(item => item.index === index)


    // Fake Participant
    // if (!participant) {
    //   var imageSamples = participants.filter(item => item.image !== "")
    //   participant = imageSamples[Math.floor(Math.random() * imageSamples.length)];
    // }

    if (participant) {
      return participant;
    }
    return null;
  }



  const _onParticipate = (index) => {
    setSearchModalIsOpen(false)
    setSelectedIndex(index);
    user = HttpService.getUser()

    if (!user) {
      setLoginModalIsOpen(true);
    }
    else {
      setParticipateModalIsOpen(index);
    }
  }




  const _animateIndex = (index) => {
    setSearchModalIsOpen(false)
    index = index  - startIndex
    setToAnimateIndex(index)
    
    // Utils.clearUrl()
    // setToAnimateIndex(false)

    //setRef
  }



  const _onAddNew = () => {
    let index;
    for (let i = 0; i < 7000; i++) {
      let participant = participants?.find(item => item.index === i);
      if (!participant) {
        index = i;
        break;
      }
    }

    if (!index) {
      index = participants?.length>0 ? participants.length : 1;
    }



    user = HttpService.getUser()


    setToAnimateIndex(-1)

    if (!user) {
      setSelectedIndex(index)
      setLoginModalIsOpen(true);

    }
    else {
      if (already_participated) {
        setSelectedIndex(already_participated.index)
        setParticipantViewModalIsOpen(true);
      }
      else { 
        setSelectedIndex(index)
        setParticipateModalIsOpen(index);
      }
    }

  }




  const Squares = () => {

    console.log("rendering squares")

    const cachedValue = useMemo(() =>
    (
      Array(numberOfSquares).fill().map((x, index) => {
        index = index + startIndex
        var participant = _getParticipantByIndex(index + 1);

        //const randomNumber = Math.floor(Math.random() * 8) + 1
        //var _duration =  randomNumber;
        //let animationclass =  ""; // randomNumber%3===0 ? ( 'square-zoomIn') : "";
        return (
          <div key={`square${index}`}
            // style={{ animationDuration: `${_duration}s !important`}}
            className={`square ${toAnimateIndex - 1 === index ? "square-scaleUp" : ""} ${squaresAreVisible ? "" : "hide"} ${participant ? 'square-zoom' : ""} ${index > 7000 ? 'hide-on-xsmall' : ""}`}
            // data-wow-delay={`${0.2}s`}
            style={{ margin: squareMargin, width: squareWidth ?? "1%" , }}
            onClick={() => { _handleSquareClick(index + 1) }}
          >
            {(participant && (participant.thumb || participant.image)) ? (
              <img src={participant.thumb ? participant.thumb : participant.image} 
                style={{ opacity: squareOpacity }}
              alt="" />
            ) :
              <div className='empty-square' title="Add your image here">
                <img src="assets/images/plus.png" className='hide transition' alt="" />
              </div>}
          </div>
        )
      }
      ))
      , [])

    return cachedValue
  }



  return (

    <>
      <section id="section-home" className='squares nice-scroll p-0' style={{ backgroundImage: `${campaign ? `url(${campaign.image})` : "url(assets/images/background/jina.jpg)"}` }}>
        <MobileMenu onAdd={_onAddNew} onSearch={() => { setSearchModalIsOpen(true) }} />
        <div className="campaign-menu hide-on-small">

          <button onClick={_onAddNew} className="btn btn-dark mr-2" title="Add your photo"><i className='fa fa-plus'></i></button>
          <button onClick={() => { setSearchModalIsOpen(true) }} title="Search in campaign" className="btn btn-dark"><i className='fa fa-search'></i></button>
          {campaign &&
            (
              <img src={`${campaign.thumb}`}
                className={`${squaresAreVisible ? "grayscale" : ""}`}
                alt=""
                title="View background"
                onClick={() => { setSquaresAreVisible(!squaresAreVisible); }} />
            )}
        </div>
        <Squares />


      </section>

      <LoginModal key={"lm" + loginModalIsOpen}
        onLoggedIn={_onLoggedIn}
        isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

      />
      <ParticipateModal key={"pm" + participateModalIsOpen}
        campaign={campaign?.id}
        userImage={user_image}
        onSaveSuccess={(campaignParticipant) => {
          var temp = participants;
          temp.push(campaignParticipant);
          setParticipans(temp);
          setAlreadyParticipated(campaignParticipant)
          setParticipateModalIsOpen(false);
          // Shows participant of the selectedIndex
          setParticipantViewModalIsOpen(true);

        }}
        isOpen={participateModalIsOpen} setOpen={setParticipateModalIsOpen} />

      <ParticipantViewModal key={"pntm" + participantViewModalIsOpen}
        index={selectedIndex}
        isOpen={participantViewModalIsOpen}
        setOpen={setParticipantViewModalIsOpen}
        participant={_getParticipantByIndex(selectedIndex)}
        onDelete={()=>{
          setParticipantViewModalIsOpen(false)

          let temp = participants.filter(participant=>!participant.id ===selectedIndex)
          setParticipans(temp)
          setAlreadyParticipated(false)

          setParticipateModalIsOpen(false)
        }

        } />
      <SearchCampaignModal participants={participants}
        key={"sm" + searchModalIsOpen}
        isOpen={searchModalIsOpen}
        setOpen={setSearchModalIsOpen}
        onParticipate={_onParticipate}
        onIndexSelected={(index)=>{

          if(index > 1000) {
            setStartIndex(index-1000)
          }
          else{
            setStartIndex(0)
          }
          _animateIndex(index)
        }}
      />

    </>
  )
}

