import { createContext, useEffect, useState } from "react";
import { HttpService } from "../services/http";
import { Utils } from "../utils";

export const UserContext = createContext();

export const UserContextProvider = (props) => {

  const [authenticate, setAuthenticate] = useState();

  const urlParams = new URLSearchParams(window.location.search); 
  const signout = urlParams.get('signout')

  if(signout){
    HttpService.signOut();
    setAuthenticate(null) // why this is not working ?

    //removing the ?signout=1 from the url
    Utils.clearUrl()
  }
  

  let localStoageToken = window.localStorage.getItem("authenticate");
  useEffect(() => {
    if (localStoageToken) {
      setAuthenticate(JSON.parse(localStoageToken));
    }

   

  }, []);

  return (
    <UserContext.Provider value={{ setAuthenticate, authenticate }}>
      {props.children}
    </UserContext.Provider>
  );
};
