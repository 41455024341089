import React from 'react'

function Footer() {
  return (
    <footer className="p-0">
            <div className="subfooter" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-md-6" >
                            © Copyright 2022 <span className="id-color">Art Gallery</span>
                        </div>
                        <div className="col-md-6 text-right" >
                            <div className="social-icons" >
                                <a href="#"><i className="fa fa-facebook fa-lg"></i></a>
                                <a href="#"><i className="fa fa-twitter fa-lg"></i></a>
                                <a href="#"><i className="fa fa-rss fa-lg"></i></a>
                                <a href="#"><i className="fa fa-google-plus fa-lg"></i></a>
                                <a href="#"><i className="fa fa-skype fa-lg"></i></a>
                                <a href="#"><i className="fa fa-dribbble fa-lg"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <a href="#" id="back-to-top"></a>
        </footer>
  )
}

export default Footer