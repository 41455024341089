import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { CampaignHttpService } from '../../../services/campaign'
import CampaignForm from '../../components/forms/campaign/CampaignForm'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import MainMenu from '../../components/shared/MainMenu'
import { useParams } from "react-router-dom";
import UserForm from '../../components/forms/user/UserForm'

function UserDetails(props) {

  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    setLoading(true);
    // CampaignHttpService.loadCampaign(id).then(data=>{
    // setLoading(false);
    //   setData(data)
    //   setCount(data.length)
    // })
  }


  return (
    <div id="wrapper">
      <Header />
      <div id="content" className='container pb-0'>
        <div className='box'>
          <div className="box-header mb-5">
            <h5>User Details</h5>
            <div className="btns">
              {/* <Link to="/cms/campaign" className="btn"><i className="fa fa-plus"></i></Link> */}
            </div>
          </div>
          <div className="box-body">
            <div className="row">
              {/* <MainMenu /> */}
              <div className="col-md-12">
                <UserForm  key={"cam" + loading} id={id} />
              </div>
            </div>
          </div>
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default UserDetails