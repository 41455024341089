import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { HttpService } from '../../../services/http';
import { UserHttpService } from '../../../services/user';
import SocialLogin from './SocialLogin';

function LoginForm({ ...props }) {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const OnSubmit = (data) => {
    if (loading) return;

    setLoading(true);
    var body = {
      "email": data.email,
      "username": data.email,
      "password": data.password
    }
    UserHttpService.login(body).then(response => {
      setLoading(false);
      if (response.detail) {
        alert("error")
      }
      else {
        HttpService.setToken(response.access)
        HttpService.setUser(response)
        if (props.onLoggedIn!=null) {
          props.onLoggedIn(response)
        }
        else {
          if (response.role == 'admin') {
            window.location.href = "/cms"
          }
          else {
            window.location.href = "/"
          }
        }
      }
    });
  };

  return (
    <>
          <div className="mb-2">
            Login
          </div>
          <form className='styled_form' onSubmit={handleSubmit(OnSubmit)} >
            <div className="row" >
              <div className="col-md-6">
                <input type="email" error={errors.email} {...register("email", { required: true })} className={`form-control ${errors.email ? "has_error" : ""} `} placeholder="Email" />
              </div>
              <div className="col-md-6">
                <input type="password" error={errors.password} {...register("password", { required: true })} className={`form-control ${errors.password ? "has_error" : ""} `} placeholder="Password" />
              </div>

              <div className="col-md-12" >
                {loading && (
                  <button className="btn p-2" type="button" disabled>
                    <span className="spinner-grow text-warning spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )}
                {!loading && (
                  <input type="submit" value="Login" className="btn btn-line rounded-25 pull-right" />
                )}
                {/* <div className="pull-right">
                    Not a member ? &nbsp;&nbsp; 
                    <input type="button" onClick={props.goToRegister}  id="send_message" value="Register Now" className="btn btn-line" />
                    
                    </div> */}
              </div>
            </div>

            {error && (
              <>
                <div className="alert alert-danger mt-4">
                  {error}
                </div>
              </>
            )}
          </form>
    </>

  )
}

export default LoginForm