import { HttpService } from "../http";


export class UserHttpService {

    static async login(body){
        var service = new HttpService();
        var response = await service.post('/api/token/',body);
        return response;
    }

    static async loadUser(id){
        var service = new HttpService();
        var response = await service.get(`/api/user/${id}`,null);
        return response;
    }

    static async saveUser(body){
        var service = new HttpService();
        var response;

        if(body.id){
            var id = body.id;
            delete body["id"]
            delete body["user"]
            
            response = await service.put(`/api/user/${id}`,body);
        }
         else
            response = await service.post('/api/register/',body);
        return response;
    }

    static async registerSocial(body){
        var service = new HttpService();
        var response;

        response = await service.post('/api/register/social/',body);
        return response;
    }

    static async loadProfile(){
        var service = new HttpService();
        var response = await service.get(`/api/profile/`,null);
        return response;
    }

    static async saveProfile(body){
        var service = new HttpService();
        var response;
        response = await service.post(`/api/profile/update/`,body);
        return response;
    }

    

}

